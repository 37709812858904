import { graphql } from 'gatsby';
import jump from 'jump.js';
import React from 'react';
import HeadTags from '../components/global/headTags';
import Hero from '../components/global/hero';
import translations from '../utils/translations';
import basicMapDataToComponents from './basicMapDataToComponents';
import DefaultLayout from '../layouts/default-layout';

const exploreClick = () => {
  jump('#content');
};

class Challenge extends React.Component {
  constructor() {
    super();
    this.state = {
      startPage: false,
      isExiting: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    // issue where first page click doesn't transition properly, so manually doing
    // transition here.
    if (nextProps.transition.status === 'exiting') {
      this.setState({ isExiting: true });
    }
  }

  componentDidMount() {
    this.setState({
      isExiting: false,
    });
    setTimeout(this.startPage, 1000);
  }

  startPage = () => {
    this.setState({ startPage: true });
  };

  render() {
    const {
      title,
      meta_title,
      meta_description,
      meta_image,
      content_type,
      paragraphs,
      short_description,
      featured_image,
      video,
      video_embed,
    } = this.props.data.allItemsJson.edges[0].node;

    return (
      <DefaultLayout {...this.props} excludeParentFunctions>
        <div
          className={`page-challenge`}
          style={
            this.state.isExiting
              ? {
                opacity: 0,
                transition: `opacity 500ms ease-in-out`,
              }
              : {
                opacity: 1,
                transition: `opacity 500ms ease-in-out`,
              }
          }
        >
          <HeadTags
            title={meta_title ? meta_title : title}
            description={meta_description}
            image={
              meta_image
                ? meta_image.sizes.hero_md
                : featured_image ? featured_image.image.sizes.hero_md : null
            }
            imageTwitter={
              meta_image
                ? meta_image.sizes.hero_sm
                : featured_image ? featured_image.image.sizes.hero_sm : null
            }
            location={this.props.location}
          />

          <Hero
            label={translations.t('Challenge')}
            title={title}
            short_description={short_description}
            imageSrc={featured_image ? featured_image.image.sizes.hero_lg : null}
            posterSrc={featured_image ? featured_image.image.sizes.hero_md : null}
            videoSrc={video ? video.url : null}
            videoEmbed={video_embed ? video_embed : null}
            shouldReveal={this.state.startPage}
            scrollExplore={exploreClick}
          />

          <div id="content" className="content-challenge">
            {basicMapDataToComponents(paragraphs, { reveal: true })}
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

export default Challenge;

// eslint-disable-next-line
export const challengePageQuery = graphql`
query ChallengePageContentByPath(
  $path: String!,
  $idRegex: String!,
  $isEs: Boolean!,
  $blog: String!,
  $translations: String!
) {
  allItemsJson(filter: {path: {eq: $path}}) {
    edges {
      node {
        title
        meta_title
        meta_description
        meta_image {
          sizes {
            hero_md
            hero_sm
          }
        }
        short_description
        content_type
        related_challenge {
          title
          path
        }
        featured_image {
          image {
            sizes {
              hero_lg
              hero_sm
              hero_md
              original
            }
          }
        }
        video {
          url
        }
        video_embed {
          url
          id
        }
        video {
          url
        }
        video_embed {
          url
          id
          name
        }
        ...queryParagraphFields
      }
    }
  }

  ...headerFields @skip(if: $isEs)
  ...footerFields @skip(if: $isEs)
  ...headerFieldsEs @include(if: $isEs)
  ...footerFieldsEs @include(if: $isEs)
  challenges: allItemsJson(
    filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        content_type
        path
        related_challenge {
          title
          path
        }
        core_curriculum {
          title
          path
        }
      }
    }
  }
  pages: allItemsJson(
    filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
    edges {
      node {
        translations {
          id
          key
          value
        }
      }
    }
  }
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
      projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
}
`;
